import { useEffect } from "react"

const LogoutPage = () => {

  useEffect(() => {
    window.location.reload();
  }, [])

  return (
    <div>
      
    </div>
  )
}

export default LogoutPage
